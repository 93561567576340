<template>
  <SectionItem
    :title="$t('settings.connected.to', targs)"
    v-if="googleProvider"
  >
    <template #action>
      <div>
        <div
          ref="label"
          class="text-body-2 font-weight-medium"
          v-text="googleProvider.email"
        />
        <div class="text-caption text-right">
          <a ref="disconnect" class="accent--text" @click="disconnect">{{
            $t("settings.connected.disconnect")
          }}</a>
        </div>
      </div>
    </template>
    {{ $t("settings.connected.sign-in", targs) }}
  </SectionItem>

  <SectionItem :title="$t('settings.connect.to', targs)" v-else>
    <template #action>
      <SectionButton ref="connect" @click="connect">
        <v-icon small class="pr-2">mdi-google</v-icon>
        <span>{{ $t("settings.connect.to", targs) }}</span>
      </SectionButton>
    </template>
    {{ $t("settings.connect.sign-in", targs) }}
  </SectionItem>
</template>

<script>
import { mapGetters } from "vuex";
import SectionItem from "./utils/SectionItem";
import SectionButton from "./utils/SectionButton";

export default {
  name: "ConnectionsGoogle",
  components: {
    SectionItem,
    SectionButton,
  },
  computed: {
    ...mapGetters({
      googleProvider: "user/googleProvider",
      company: "config/company",
    }),
    targs() {
      return {
        provider: this.$t("google"),
        company: this.company,
      };
    },
  },
  methods: {
    connect() {
      this.$firebase.linkWithGoogleRedirect();
      this.$store.dispatch("user/setData", this.$firebase.currentUser);
    },
    disconnect() {
      this.$firebase.unlinkGoogleProvider();
      this.$store.dispatch("user/setData", this.$firebase.currentUser);
    },
  },
};
</script>
