<template>
  <SectionItem :title="$t('settings.export.label')">
    <template #action>
      <SectionButton :to="{ name: 'user/export' }">
        {{ $t("settings.export.download") }}
      </SectionButton>
    </template>
    {{ $t("settings.export.description", { company }) }}
  </SectionItem>
</template>

<script>
import { mapGetters } from "vuex";
import SectionItem from "./utils/SectionItem";
import SectionButton from "./utils/SectionButton";

export default {
  name: "AccountExport",
  components: {
    SectionItem,
    SectionButton,
  },
  computed: {
    ...mapGetters({
      company: "config/company",
    }),
  },
};
</script>
