<template>
  <SectionItem :title="$t('settings.subscriptions.emails from', [company])">
    <template #action>
      <v-btn-toggle
        v-if="loaded"
        :value="subscriptions.all"
        @change="(all) => updateSubscriptions({ all })"
        color="accent"
        mandatory
        id="all"
        :data-value="subscriptions.all"
      >
        <v-btn
          small
          id="on"
          class="text-none font-weight-regular"
          :value="false"
        >
          {{ $t("on") }}
        </v-btn>
        <v-btn
          small
          id="off"
          class="text-none font-weight-regular"
          :value="true"
        >
          {{ $t("off") }}
        </v-btn>
      </v-btn-toggle>
    </template>
    {{ $t("settings.subscriptions.all", { company }) }}
  </SectionItem>
</template>

<script>
import { mapGetters } from "vuex";
import SectionItem from "./utils/SectionItem";

export default {
  name: "EmailSubscriptions",
  components: {
    SectionItem,
  },
  data: () => ({
    ref: null,
    loaded: false,
    subscriptions: {
      all: false,
    },
  }),
  computed: {
    ...mapGetters({
      uid: "user/uid",
      company: "config/company",
    }),
  },
  methods: {
    async updateSubscriptions(subscriptions) {
      Object.assign(this.subscriptions, subscriptions);
      await this.$firebase.setDoc(this.ref, subscriptions, { merge: true });
    },
  },
  async created() {
    const baseRef = this.$route.matched
      .find((r) => r.meta.ref)
      .meta.ref(this.$route);
    this.ref = this.$firebase.doc(baseRef, "account/emailSubscriptions");
    try {
      const subscriptionsDoc = await this.$firebase.getDoc(this.ref);
      if (!subscriptionsDoc.exists()) {
        return;
      }
      Object.assign(this.subscriptions, subscriptionsDoc.data());
    } finally {
      this.loaded = true;
    }
  },
};
</script>
