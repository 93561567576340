<template>
  <div class="pb-9">
    <v-card-title ref="header" class="text-h5 font-weight-medium">{{
      title
    }}</v-card-title>
    <v-divider></v-divider>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Section",
  props: {
    title: { type: String, required: true },
  },
};
</script>
