<template>
  <SectionTextField
    class="email-field"
    :isEditing="isEditing"
    :disabled="loading"
    :title="$t('settings.address.label')"
    v-model="value"
    @edit="checkSignIn"
    @save="onSave"
    @cancel="onCancel"
  >
    <template #action>{{ $t("settings.address.edit") }}</template>
  </SectionTextField>
</template>

<style>
.email-field .readonly {
  border-bottom: none !important;
}
</style>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import SectionTextField from "./utils/SectionTextField";
export default {
  name: "EmailAddress",
  components: {
    SectionTextField,
  },
  data: () => ({
    loading: false,
    value: "",
    isEditing: false,
  }),
  computed: {
    ...mapGetters({
      email: "user/email",
    }),
  },
  methods: {
    checkSignIn() {
      const lastSignIn = this.$firebase.currentUser.metadata.lastSignInTime;
      if (moment().diff(lastSignIn, "hour") >= 1) {
        this.$emit("reauthenticate");
        return;
      }
      this.isEditing = true;
    },
    async onSave() {
      if (this.value === this.email) {
        this.onCancel();
        return;
      }
      try {
        this.loading = true;
        await this.$firebase.updateEmail(this.value);
        this.$store.dispatch("user/setData", this.$firebase.currentUser);
        this.isEditing = false;
      } finally {
        this.loading = false;
      }
    },
    onCancel() {
      this.value = this.email;
      this.isEditing = false;
    },
  },
  mounted() {
    this.value = this.email;
  },
};
</script>
