<template>
  <SectionItem :title="$t('settings.delete.label')">
    <div>{{ $t("settings.delete.description") }}</div>
    <v-btn
      text
      :ripple="false"
      id="deleteBtn"
      class="pa-0 text-none text-body-2 font-weight-light text-decoration-underline"
      @click="checkSignIn"
    >
      {{ $t("settings.delete.delete") }}
    </v-btn>
    <v-overlay :opacity="0.95" color="white" v-model="dialog">
      <v-card
        v-click-outside="onClickOutside"
        light
        color="transparent"
        flat
        tile
        class="mx-auto"
        max-width="580"
        id="dialog"
        ref="overlay"
      >
        <v-btn id="closeBtn" icon fab fixed top right @click="onClickOutside">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-card flat tile class="mx-auto" style="padding: 100px 5px">
          <div class="mb-6 text-h5 font-weight-bold text-center">
            {{ $t("settings.delete.confirm.label") }}
          </div>
          <p class="mb-7 text-subtitle-1 text-center font-weight-light">
            <i18n path="settings.delete.confirm.description">
              <template #contact>
                <a class="accent--text" :href="`mailto:${supportEmail}`">{{
                  supportEmail
                }}</a>
              </template>
            </i18n>
          </p>
          <p class="mb-7 text-subtitle-1 text-center font-weight-light">
            <i18n path="settings.delete.confirm.instruction">
              <template #email>
                <span class="font-weight-regular">{{ email }}</span>
              </template>
            </i18n>
          </p>
          <div>
            <v-text-field id="confirmInput" v-model="value" outlined dense />
            <div class="d-flex flex-row justify-center">
              <SectionButton
                id="confirmBtn"
                :disabled="value !== email"
                class="primary--text mx-1"
                @click="onConfirmDelete"
              >
                {{ $t("settings.delete.confirm.action") }}
              </SectionButton>
              <SectionButton
                id="cancelBtn"
                class="mx-1"
                @click="onClickOutside"
              >
                {{ $t("cancel") }}
              </SectionButton>
            </div>
          </div>
        </v-card>
      </v-card>
    </v-overlay>
  </SectionItem>
</template>

<style scoped>
#deleteBtn:hover {
  background-color: transparent;
  color: #ff5252;
}
#deleteBtn:before {
  background-color: transparent;
  color: #ff5252;
}
</style>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import SectionItem from "./utils/SectionItem";
import SectionButton from "./utils/SectionButton";
export default {
  name: "SecurityDelete",
  components: {
    SectionItem,
    SectionButton,
  },
  data: () => ({
    dialog: false,
    value: "",
  }),
  computed: {
    ...mapGetters({
      supportEmail: "config/supportEmail",
      email: "user/email",
    }),
  },
  methods: {
    onClickOutside() {
      this.dialog = false;
    },
    onEscapePressed(e) {
      if (e.code === "Escape") {
        this.onClickOutside();
      }
    },
    checkSignIn() {
      const lastSignIn = this.$firebase.currentUser.metadata.lastSignInTime;
      if (moment().diff(lastSignIn, "hour") >= 1) {
        this.$emit("reauthenticate");
        return;
      }
      this.dialog = true;
    },
    async onConfirmDelete() {
      this.dialog = false;
      await this.$firebase.currentUser.delete();
    },
  },
  watch: {
    dialog(on) {
      if (on) {
        document.querySelector("html").style.overflow = "hidden";
        window.addEventListener("keyup", this.onEscapePressed);
      } else {
        document.querySelector("html").style.overflow = "";
        window.removeEventListener("keyup", this.onEscapePressed);
        this.value = "";
      }
    },
  },
};
</script>
