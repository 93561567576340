<template>
  <v-main data-settings>
    <div>
      <v-card flat max-width="1040" class="mx-auto">
        <v-container>
          <v-row dense no-gutters>
            <v-col cols="3" class="d-none d-md-flex">
              <v-card flat style="position: fixed" max-width="280">
                <v-list class="pb-8">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-h6 font-weight-medium">
                        {{ $t("settings.label") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item href="#profile">
                    <v-list-item-content>
                      <v-list-item-subtitle
                        class="text-subtitle-1 font-weight-regular"
                      >
                        {{ $t("settings.profile") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item href="#email">
                    <v-list-item-content>
                      <v-list-item-subtitle
                        class="text-subtitle-1 font-weight-regular"
                      >
                        {{ $t("settings.email") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item href="#connections">
                    <v-list-item-subtitle
                      class="text-subtitle-1 font-weight-regular"
                    >
                      {{ $t("settings.connections") }}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item href="#account">
                    <v-list-item-subtitle
                      class="text-subtitle-1 font-weight-regular"
                    >
                      {{ $t("settings.account") }}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item href="#security">
                    <v-list-item-subtitle
                      class="text-subtitle-1 font-weight-regular"
                    >
                      {{ $t("settings.security") }}
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>

                <v-divider></v-divider>
                <Footer />
              </v-card>
            </v-col>

            <v-col>
              <v-card flat>
                <a name="profile" />
                <Section :title="$t('settings.profile')">
                  <ProfileName data-settings-profile="name" />
                  <ProfilePhoto data-settings-profile="photo" />
                </Section>
                <a name="email" />
                <Section :title="$t('settings.email')">
                  <EmailAddress
                    data-settings-email="address"
                    @reauthenticate="onReauthenticate"
                  />
                  <v-divider></v-divider>
                  <EmailSubscriptions data-settings-email="subscriptions" />
                </Section>
                <a name="connections" />
                <Section :title="$t('settings.connections')">
                  <ConnectionsGoogle data-settings-connections="google" />
                </Section>
                <a name="account" />
                <Section :title="$t('settings.account')">
                  <AccountExport data-settings-accountInfo="export" />
                </Section>
                <a name="security" />
                <Section :title="$t('settings.security')">
                  <SecurityDelete
                    data-settings-security="delete"
                    @reauthenticate="onReauthenticate"
                  />
                </Section>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";
import Section from "@/components/settings/utils/Section";
import ProfileName from "@/components/settings/ProfileName";
import ProfilePhoto from "@/components/settings/ProfilePhoto";
import EmailAddress from "@/components/settings/EmailAddress";
import EmailSubscriptions from "@/components/settings/EmailSubscriptions";
import ConnectionsGoogle from "@/components/settings/ConnectionsGoogle";
import AccountExport from "@/components/settings/AccountExport";
import SecurityDelete from "@/components/settings/SecurityDelete";
import Footer from "@/components/home/Footer";
export default {
  name: "Settings",
  components: {
    Section,
    ProfileName,
    ProfilePhoto,
    EmailAddress,
    EmailSubscriptions,
    ConnectionsGoogle,
    AccountExport,
    SecurityDelete,
    Footer,
  },
  computed: {
    ...mapGetters({
      email: "user/email",
      googleProvider: "user/googleProvider",
    }),
  },
  methods: {
    async onReauthenticate() {
      // first try google if it is available
      if (this.googleProvider) {
        try {
          await this.$firebase.signInWithGoogleRedirect();
          return;
        } catch (err) {
          console.error(
            "Could not log in through Google, falling back to email",
            err.message
          );
        }
      }

      // then try email if that doesn't work
      const email = this.email;
      await this.$firebase.sendSignInLinkToEmail(email, this.$route.fullPath);
      this.$store.dispatch(
        "alert/info",
        this.$t("settings.reauthenticate", { email })
      );
    },
  },
};
</script>
