<template>
  <SectionItem :title="title">
    <template #action>
      <div class="d-flex flex-row align-center">
        <v-progress-circular
          indeterminate
          size="23"
          color="primary"
          v-if="loading"
        />
        <SectionEditButton
          class="pl-2"
          :isEditing="isEditing"
          :disabled="loading"
          @edit="$emit('edit')"
          @save="onSave"
          @cancel="onCancel"
        />
      </div>
    </template>

    <v-dialog width="600" v-model="showPhotoEditor">
      <SectionPhotoEditor
        :src="selectedImage"
        @crop="onCrop"
        @cancel="showPhotoEditor = false"
      />
    </v-dialog>

    <div class="d-flex flex-row">
      <div><slot></slot></div>

      <div class="d-flex flex-row flex-grow-1 justify-end pl-4">
        <input
          ref="filePicker"
          accept="image/jpeg,image/png"
          type="file"
          @change="loadImage"
          style="position: absolute; opacity: 0"
          v-if="isEditing"
        />

        <v-btn
          x-large
          fab
          id="picker"
          :disabled="!isEditing || loading"
          @click="showFilePicker"
          style="background-color: white !important"
        >
          <v-avatar size="72" v-if="value">
            <img :src="value" />
          </v-avatar>
          <v-avatar
            id="overlay"
            size="72"
            color="black"
            :style="
              value ? 'position: absolute; top: 0; left: 0; opacity: .4' : ''
            "
            v-show="isEditing"
          />
          <v-icon
            size="48"
            style="position: absolute; top: 21px; left: 20px"
            color="grey lighten-1"
            v-show="isEditing"
            >mdi-camera-outline</v-icon
          >
        </v-btn>
      </div>
    </div>
  </SectionItem>
</template>

<script>
import SectionItem from "./SectionItem";
import SectionEditButton from "./SectionEditButton";
import SectionPhotoEditor from "./SectionPhotoEditor";

export default {
  name: "SectionPhoto",
  components: {
    SectionItem,
    SectionEditButton,
    SectionPhotoEditor,
  },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    loading: { type: Boolean, default: false },
    isEditing: { type: Boolean, default: false },
    title: { type: String, required: true },
    value: { type: String },
  },
  data: () => ({
    showPhotoEditor: false,
    selectedImage: null,
  }),
  methods: {
    loadImage() {
      const files = this.$refs.filePicker.files;
      if (files.length && files[0]) {
        const file = files[0];
        this.clearImage();
        this.selectedImage = URL.createObjectURL(file);
        this.showPhotoEditor = true;
      }
    },
    clearImage() {
      if (this.selectedImage) {
        URL.revokeObjectURL(this.selectedImage);
        this.selectedImage = null;
      }
    },
    showFilePicker() {
      this.$refs.filePicker.value = "";
      this.$refs.filePicker.click();
    },
    onCrop(base64Image) {
      this.showPhotoEditor = false;
      this.$emit("input", base64Image);
    },
    onSave() {
      this.clearImage();
      this.$emit("save");
    },
    onCancel() {
      this.clearImage();
      this.$emit("cancel");
    },
  },
};
</script>
