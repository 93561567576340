<template>
  <SectionPhoto
    :isEditing="isEditing"
    :loading="loading"
    :title="$t('settings.photo.label')"
    v-model="value"
    @edit="isEditing = true"
    @save="onSave"
    @cancel="onCancel"
  >
    <i18n path="settings.photo.description">
      <template #profile>
        <a href="#profile" class="accent--text">{{ $t("settings.profile") }}</a>
      </template>
    </i18n>
    <br /><br />
    {{ $t("settings.photo.recommend") }}
  </SectionPhoto>
</template>

<script>
import { mapGetters } from "vuex";
import SectionPhoto from "./utils/SectionPhoto";

export default {
  name: "ProfilePhoto",
  components: {
    SectionPhoto,
  },
  data: () => ({
    value: "",
    isEditing: false,
    loading: false,
  }),
  computed: {
    ...mapGetters({
      uid: "user/uid",
      photoURL: "user/photoURL",
    }),
  },
  methods: {
    async onSave() {
      if (this.value === (this.photoURL ?? "")) {
        this.isEditing = false;
        return;
      }

      this.loading = true;
      try {
        const ref = this.$firebase.ref(`users/${this.uid}/images/me.png`);
        await this.$firebase.uploadFromDataURL(ref, this.value);
        const photoURL = await this.$firebase.getDownloadURL(ref);
        await this.$firebase.updateProfile({ photoURL });
        this.$store.dispatch("user/setData", this.$firebase.currentUser);
        this.value = photoURL;
        this.isEditing = false;
      } finally {
        this.loading = false;
      }
    },
    onCancel() {
      this.value = this.photoURL ?? "";
      this.isEditing = false;
    },
  },
  mounted() {
    this.value = this.photoURL ?? "";
  },
};
</script>
