<template>
  <div>
    <template v-if="isEditing">
      <SectionButton
        data-settings-section="save"
        class="primary--text mr-2"
        :disabled="disabled"
        @click="$emit('save')"
      >
        {{ $t("save") }}
      </SectionButton>

      <SectionButton
        data-settings-section="cancel"
        :disabled="disabled"
        @click="$emit('cancel')"
      >
        {{ $t("cancel") }}
      </SectionButton>
    </template>

    <template v-else>
      <SectionButton
        data-settings-section="edit"
        :disabled="disabled"
        @click="$emit('edit')"
      >
        <slot>{{ $t("edit") }}</slot>
      </SectionButton>
    </template>
  </div>
</template>

<script>
import SectionButton from "./SectionButton";
export default {
  name: "SectionEditButton",
  components: {
    SectionButton,
  },
  props: {
    isEditing: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
};
</script>
