<template>
  <div class="d-flex flex-row pa-4">
    <div class="flex-grow-1">
      <div ref="header" class="text-h6">{{ title }}</div>
      <div class="d-flex flex-row">
        <div class="text-body-2 font-weight-light flex-grow-1">
          <slot></slot>
        </div>
      </div>
    </div>
    <div style="min-width: 195px; position: relative">
      <div style="position: absolute; top: 0; right: 0">
        <slot name="action"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionItem",
  props: {
    title: { type: String, required: true },
  },
};
</script>
