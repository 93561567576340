<template>
  <SectionTextField
    :isEditing="isEditing"
    :disabled="loading"
    :title="$t('settings.name.label')"
    v-model="value"
    @edit="isEditing = true"
    @save="onSave"
    @cancel="onCancel"
  >
    <i18n path="settings.name.description">
      <template #profile>
        <a href="#profile" class="accent--text">{{ $t("settings.profile") }}</a>
      </template>
    </i18n>
  </SectionTextField>
</template>

<script>
import { mapGetters } from "vuex";
import SectionTextField from "./utils/SectionTextField";
export default {
  name: "ProfileName",
  components: {
    SectionTextField,
  },
  data: () => ({
    loading: false,
    value: "",
    isEditing: false,
  }),
  computed: {
    ...mapGetters({
      displayName: "user/displayName",
    }),
  },
  methods: {
    async onSave() {
      if (this.value === this.displayName) {
        this.onCancel();
        return;
      }

      this.loading = true;
      try {
        await this.$firebase.updateProfile({ displayName: this.value });
        this.$store.dispatch("user/setData", this.$firebase.currentUser);
        this.isEditing = false;
      } finally {
        this.loading = false;
      }
    },
    onCancel() {
      this.value = this.displayName;
      this.isEditing = false;
    },
  },
  mounted() {
    this.value = this.displayName;
  },
};
</script>
