<template>
  <v-card>
    <v-card height="600" class="mx-auto pa-4" flat color="black">
      <Cropper
        ref="cropper"
        style="width: 100%; height: 100%"
        stencil-component="circle-stencil"
        image-restriction="stencil"
        :src="src"
        :stencil-props="{
          handlers: {},
          moveable: false,
          scalable: false,
          aspectRatio: 1,
        }"
        :canvas="false"
        @ready="loading = false"
      />
    </v-card>

    <v-card-actions>
      <SectionButton
        data-settings-photo-editor="crop"
        class="primary--text"
        :disabled="loading"
        @click="onCrop"
      >
        {{ $t("crop") }}
      </SectionButton>

      <SectionButton
        data-settings-photo-editor="cancel"
        :disabled="loading"
        @click="$emit('cancel')"
      >
        {{ $t("cancel") }}
      </SectionButton>
    </v-card-actions>
  </v-card>
</template>

<script>
import SectionButton from "./SectionButton";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "SectionPhotoEditor",
  components: {
    SectionButton,
    Cropper,
  },
  props: {
    src: { type: String },
  },
  data: () => ({
    maxSize: 1000,
    loading: true,
  }),
  methods: {
    onCrop() {
      this.loading = true;

      const cropper = this.$refs.cropper;
      const { coordinates: coords } = cropper.getResult();
      const img = cropper.$el.querySelector("img");
      const canvas = document.createElement("CANVAS");

      if (coords.width > this.maxSize) {
        canvas.width = this.maxSize;
        canvas.height = this.maxSize;

        canvas
          .getContext("2d")
          .drawImage(
            img,
            coords.left,
            coords.top,
            coords.width,
            coords.height,
            0,
            0,
            this.maxSize,
            this.maxSize
          );
      } else {
        canvas.width = coords.width;
        canvas.height = coords.height;

        canvas
          .getContext("2d")
          .drawImage(
            img,
            coords.left,
            coords.top,
            coords.width,
            coords.height,
            0,
            0,
            coords.width,
            coords.height
          );
      }
      this.$emit("crop", canvas.toDataURL());
    },
  },
};
</script>
