<template>
  <SectionItem :title="title">
    <template #action>
      <SectionEditButton
        :isEditing="isEditing"
        :disabled="disabled"
        @edit="$emit('edit')"
        @save="$emit('save')"
        @cancel="$emit('cancel')"
        ><slot name="action"></slot
      ></SectionEditButton>
    </template>

    <v-text-field
      class="text-subtitle-1 font-weight-regular"
      ref="field"
      :value="value"
      :disabled="disabled"
      @input="(v) => $emit('input', v)"
      v-if="isEditing"
    />

    <div class="text-subtitle-1 font-weight-regular mt-1 pt-3" v-else>
      <div class="readonly">
        <div>{{ value }}</div>
      </div>
      <div style="min-height: 21px"></div>
    </div>

    <div><slot></slot></div>
  </SectionItem>
</template>

<style scoped>
.readonly {
  width: 100%;
  min-height: 33px;
  border-bottom: 1px solid rgb(118, 118, 118);
}

.readonly div {
  position: relative;
  top: 2px;
  letter-spacing: normal;
}
</style>

<script>
import SectionItem from "./SectionItem";
import SectionEditButton from "./SectionEditButton";
export default {
  name: "SectionTextField",
  components: {
    SectionItem,
    SectionEditButton,
  },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    isEditing: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    title: { type: String, required: true },
    value: { type: String },
  },
  watch: {
    async isEditing(value) {
      if (value) {
        await this.$nextTick();
        this.$refs.field.focus();
      }
    },
  },
};
</script>
